import React, { useState } from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  MysaMattressProductSection,
  MysaMattressIntroSection,
  MysaMattressFirmnessSection,
  MysaMattressBenefitSection,
  MysaMattressUpsellHeroSection,
  MysaMattressAccordionSection,
  MattressLateralSection,
  MysaMattressLayerSection,
  MysaMattressReviewHeroSection,
  MysaMattressUpsellGridSection,
  WoosaPromiseIconGridSection,
  MysaMattressProductFixedBottomBar,
} from '../../components/sections';

const MysaMattressPage: React.FC = () => {
  const [isPlusCover, setIsPlusCover] = useState(false);

  const handleIsPlusCoverChange = (isChecked: boolean) => {
    setIsPlusCover(isChecked);
  };

  return (
    <Layout>
      <SEO
        title="The Mysa Mattress, Hotel-style Latex Memory Foam Mattress Singapore"
        description="Enjoy hotel-style comfort in your bedroom. Premium Latex and Memory Foam mattresses that are made and tested for Singaporeans."
      />
      <MysaMattressProductSection
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
      <MysaMattressIntroSection />
      <MysaMattressFirmnessSection />
      <MysaMattressLayerSection isPlusCover={isPlusCover} />
      <MysaMattressBenefitSection />
      <MysaMattressUpsellHeroSection />
      <MysaMattressAccordionSection />
      <MysaMattressReviewHeroSection />
      <MysaMattressUpsellGridSection />
      <WoosaPromiseIconGridSection version="v2" />
      <MattressLateralSection version="v2" />
      <MysaMattressProductFixedBottomBar
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
    </Layout>
  );
};
export default MysaMattressPage;
